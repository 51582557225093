
(function() {
  var result = { translations: { "ar-eg": { "i18n": { "duration": { "seconds": "{{ seconds }} \u062B\u0627\u0646\u064A\u0629", "minutes": "{{ minutes }} \u062F\u0642\u064A\u0642\u0629", "hours": "{{ hours }} \u0633\u0627\u0639\u0629", "hoursAndMinutes": "{{ hours }} \u0633\u0627\u0639\u0629 {{ minutes }} \u062F\u0642\u064A\u0642\u0629", "days": "{{ days }} \u0623\u064A\u0627\u0645", "shortForm": { "seconds": "{{ seconds }} \u062B", "minutes": "{{ minutes }} \u062F", "hours": "{{ hours }} \u0633", "days": "{{ days }} \u064A", "hoursAndMinutes": "{{ hours }} \u0633 {{ minutes }} \u062F" } }, "dateTime": { "quarterAndYear": "Q{{quarterNumber}} {{yearNumber}}" }, "numberRepresentation": { "fraction": "{{ numerator }}/{{ denominator }}", "numberWithCurrencyCode": "{{currencyCode}} {{amountNumberValue}}", "phoneNumberWithExtension": "{{phoneNumber}}\u060C \u062A\u0645\u062F\u064A\u062F {{extension}}" } } } } };
  result.translationsLoaded = {
  "I18n": {
    "ar-eg": "static-7.1150"
  }
};
  result.translationsAvailable = {
  "I18n": {
    "af": "static-7.1150",
    "ar-eg": "static-7.1150",
    "bg": "static-7.1150",
    "bn": "static-7.1150",
    "ca-es": "static-7.1150",
    "cs": "static-7.1150",
    "da": "static-7.1150",
    "de": "static-7.1150",
    "el": "static-7.1150",
    "en-gb": "static-7.1150",
    "en": "static-7.1150",
    "es-mx": "static-7.1150",
    "es": "static-7.1150",
    "et": "static-7.1150",
    "fi": "static-7.1150",
    "fr-ca": "static-7.1150",
    "fr": "static-7.1150",
    "he-il": "static-7.1150",
    "hi": "static-7.1150",
    "hr": "static-7.1150",
    "hu": "static-7.1150",
    "id": "static-7.1150",
    "it": "static-7.1150",
    "ja": "static-7.1150",
    "ko": "static-7.1150",
    "lt": "static-7.1150",
    "ms": "static-7.1150",
    "nl": "static-7.1150",
    "no": "static-7.1150",
    "pl": "static-7.1150",
    "pt-br": "static-7.1150",
    "pt-pt": "static-7.1150",
    "ro": "static-7.1150",
    "ru": "static-7.1150",
    "sk": "static-7.1150",
    "sl": "static-7.1150",
    "sv": "static-7.1150",
    "th": "static-7.1150",
    "tl": "static-7.1150",
    "tr": "static-7.1150",
    "uk": "static-7.1150",
    "vi": "static-7.1150",
    "x-pseudo": "static-7.1150",
    "zh-cn": "static-7.1150",
    "zh-hk": "static-7.1150",
    "zh-tw": "static-7.1150"
  }
};
  hns('I18n', result);
  if (typeof I18n === 'object' && I18n.trigger) { I18n.trigger('loaded:I18n:ar-eg', {version: 'static-7.1150'}); }
})();


